import {
  NOSIM_SIGNATURE_TYPES,
  TRANSACTION_SIMULATION_TYPES,
  WARNING_TYPES,
} from '../../helpers/constants/application.constants';
import {
  DeepSimulationType,
  EIPTransactionTypes,
  SignatureTypes,
  philandDeepSimulationType,
} from '../../helpers/enums/application.enums';
import {
  EIPGlobalInformation,
  SimulationData,
} from '../../helpers/interfaces/dataTypes.interface';
import ErrorFallback from '../Error/ErrorFallback';
import ErrorModal from '../Error/ErrorModal';
import SimpleSignature from '../Signature/SimpleSignature';
import ENSSignature from '../Simulation/DeepSimulations/ENSSignature';
import PhilandTransactions from '../Simulation/DeepSimulations/Philand/PhilandTransactions';
import Transactions from '../Simulation/Transactions/Transactions';
import DangerWarning from '../Warnings/DangerWarning';

interface IWalletChangesViewProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Renders the necessary component for the transaction type to represent the effect of the user's wallet
const WalletChangesView: React.FC<IWalletChangesViewProps> = ({
  eipGlobalInformation,
  transactionSimulation,
  transactionType,
}) => {
  //Error case
  if (eipGlobalInformation.error) {
    return <ErrorModal error={eipGlobalInformation.error} />;
  }

  //ENS deep sim signature case
  else if (transactionType === DeepSimulationType.ENS_SIGNATURE_REQUEST) {
    return (
      <ENSSignature
        transactionSimulation={transactionSimulation}
        network={eipGlobalInformation.network}
        sourceUrl={eipGlobalInformation.sourceUrl}
      />
    );
  } else if (
    eipGlobalInformation.deepSimulationData &&
    philandDeepSimulationType.includes(
      eipGlobalInformation.deepSimulationData.type
    )
  ) {
    return (
      <PhilandTransactions
        netBalanceChanges={transactionSimulation?.netBalanceChanges}
        hexChainId={eipGlobalInformation.network}
        userAddress={eipGlobalInformation.from}
        transactionType={transactionType}
      />
    );
  }

  //Signatures case
  else if (NOSIM_SIGNATURE_TYPES.includes(transactionType as any)) {
    return <SimpleSignature type={transactionType as SignatureTypes} />;
  }
  //Dangerous transaction case
  else if (WARNING_TYPES.includes(transactionType as any)) {
    return (
      <DangerWarning
        type={EIPTransactionTypes.ETH_SIG}
        title={'Warning'}
        warningMessage={
          'Confirming this signature can transfer all of your assets. Please make sure that you trust this site before signing this transaction.'
        }
      />
    );
  }
  //Standard simulation case
  else if (TRANSACTION_SIMULATION_TYPES.includes(transactionType as any)) {
    return (
      <Transactions
        netBalanceChanges={transactionSimulation?.netBalanceChanges}
        hexChainId={eipGlobalInformation.network}
        userAddress={eipGlobalInformation.from}
        transactionType={transactionType}
      />
    );
  }
  //If we can't find a transaction type, something is wrong. Default to the internal error screen
  else {
    return <ErrorFallback />;
  }
};

export default WalletChangesView;

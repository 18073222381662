interface ILabeledDescriptionProps {
  icon: JSX.Element;
  description: string;
}

const LabeledDescription: React.FC<ILabeledDescriptionProps> = ({
  icon,
  description,
}) => {
  return (
    <div className="flex flex-row items-center py-3">
      <div>{icon}</div>
      <div className="text-xl pl-3">{description}</div>
    </div>
  );
};

export default LabeledDescription;

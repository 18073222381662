interface IFireButtonProps {
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: string;
}

const FireButton = ({ ...props }: IFireButtonProps) => {
  const primaryButtonStyle =
    'bg-secondary-800 text-white h-[44px] rounded-full text-lg font-ClashDisplay transition ease-in-out hover:scale-110 duration-300 focus:outline-none';
  return (
    <button
      className={`${primaryButtonStyle} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default FireButton;

import {
  NOSIM_SIGNATURE_TYPES,
  TRANSACTION_SIMULATION_TYPES,
} from '../../helpers/constants/application.constants';
import {
  DeepSimulationType,
  philandDeepSimulationType,
} from '../../helpers/enums/application.enums';
import {
  EIPGlobalInformation,
  SimulationData,
} from '../../helpers/interfaces/dataTypes.interface';
import Banner from '../Banner/Banner';
import SignatureBanner from '../Banner/SignatureBanner';
import PhilandBanner from '../Simulation/DeepSimulations/Philand/PhilandBanner';

interface IWalletChangesViewProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionSimulation: SimulationData | undefined;
  transactionType: string;
}

//Renders the necessary component for the transaction type to represent the affect of the user's wallet
const BannerView: React.FC<IWalletChangesViewProps> = ({
  eipGlobalInformation,
  transactionSimulation,
  transactionType,
}) => {
  //Check for deep simulation & other edge cases first
  if (
    NOSIM_SIGNATURE_TYPES.includes(transactionType as any) ||
    DeepSimulationType.ENS_SIGNATURE_REQUEST === transactionType
  ) {
    return <SignatureBanner sourceUrl={eipGlobalInformation.sourceUrl} />;
  }
  //philand claim case
  else if (
    eipGlobalInformation.deepSimulationData &&
    philandDeepSimulationType.includes(
      eipGlobalInformation.deepSimulationData.type
    )
  ) {
    return (
      <PhilandBanner
        simulationType={eipGlobalInformation.deepSimulationData.type}
        transactionSimulation={
          transactionSimulation
            ? {
                ...transactionSimulation,
                deepSimulationData: eipGlobalInformation.deepSimulationData,
              }
            : undefined
        }
        sourceUrl={eipGlobalInformation.sourceUrl}
        network={eipGlobalInformation.network}
        transactionType={transactionType}
      />
    );
  } else if (TRANSACTION_SIMULATION_TYPES.includes(transactionType as any)) {
    return (
      <Banner
        transactionSimulation={transactionSimulation}
        sourceUrl={eipGlobalInformation.sourceUrl}
        network={eipGlobalInformation.network}
        transactionType={transactionType}
      />
    );
  } else {
    return <></>;
  }
};

export default BannerView;

import { NETWORK_INFO } from '../../../../helpers/constants/application.constants';
import {
  formatTransactionSummary,
  transactionSummary,
} from '../../../../helpers/engines/transactionSummaryEngine';
import { DeepSimulationType } from '../../../../helpers/enums/application.enums';
import {
  DexSwapDetail,
  SimulationData,
} from '../../../../helpers/interfaces/dataTypes.interface';
import { reduceAddress } from '../../../../helpers/methods';

export const buildPhilandTransactionSummaryFromSimulation = (
  simulation: SimulationData,
  isSignature: boolean,
  network: string,
  erc20: DexSwapDetail[],
  erc721: DexSwapDetail[]
) => {
  let message: ReturnType<typeof buildPhilandTransactionSummary> = [];
  switch (simulation.deepSimulationData?.type) {
    case DeepSimulationType.PHILAND_LAND_DEPOSIT: {
      if (simulation.netBalanceChanges[0].from === simulation.from) {
        const action = 'deposit';
        const recipient = 'your land';
        message = buildPhilandTransactionSummary(
          [...erc20, ...erc721],
          isSignature,
          network,
          simulation.to,
          action,
          recipient
        );
      }
      break;
    }
    case DeepSimulationType.PHILAND_LAND_WITHDRAW: {
      if (simulation.netBalanceChanges[0].from === simulation.from) {
        const action = 'withdraw';
        const recipient = 'your land';
        message = buildPhilandTransactionSummary(
          [...erc20, ...erc721],
          isSignature,
          network,
          simulation.to,
          action,
          recipient
        );
      }
      break;
    }

    case DeepSimulationType.PHILAND_LAND_CLAIM: {
      if (simulation.netBalanceChanges[0].from === simulation.from) {
        const action = 'claim and transfer';
        const recipient = 'you';
        message = buildPhilandTransactionSummary(
          [...erc20, ...erc721],
          isSignature,
          network,
          simulation.to,
          action,
          recipient
        );
      }
      break;
    }

    case DeepSimulationType.PHILAND_LAND_SAVE: {
      const action = 'save your land';
      let recipient = '';
      if ('name' in simulation.deepSimulationData.data) {
        recipient = simulation.deepSimulationData.data.name + '.eth';
      }
      message = buildPhilandTransactionSummary(
        [...erc20, ...erc721],
        isSignature,
        network,
        simulation.to,
        action,
        recipient
      );

      break;
    }

    case DeepSimulationType.PHILAND_LAND_CREATE: {
      const action = 'create your land';
      let recipient = '';
      if ('name' in simulation.deepSimulationData.data) {
        recipient = simulation.deepSimulationData.data.name + '.eth';
      }
      const preposition = 'for';
      message = buildPhilandTransactionSummary(
        [],
        isSignature,
        network,
        simulation.to,
        action,
        recipient,
        preposition
      );
      break;
    }

    case DeepSimulationType.PHILAND_LAND_BUY: {
      const action = 'send ';
      let recipient = 'your land';

      message = buildPhilandTransactionSummary(
        [...erc20, ...erc721],
        isSignature,
        network,
        simulation.to,
        action,
        recipient
      );
      break;
    }

    case DeepSimulationType.PHILAND_LAND_BUY_AND_DEPOSIT: {
      const action = 'place ';
      let recipient = 'your land';

      message = buildPhilandTransactionSummary(
        [...erc20, ...erc721.filter((token) => token.type === 'Out')],
        isSignature,

        network,
        simulation.to,
        action,
        recipient
      );
      break;
    }
    default:
      throw new Error(
        'Unsupported Philand transaction type in PhilandTransactionSummary'
      );
  }
  return message;
};

export const buildPhilandTransactionSummary = (
  allTxs: any,
  isSignature: boolean | undefined,
  network: string,
  to: string,
  action: string,
  recipient: string,
  preposition?: string
) => {
  const allTransfers = allTxs.filter(
    (transaction: any) => transaction.to !== 'validators'
  );

  const outgoingTransactionSummary = transactionSummary('Out', allTxs);
  const incomingTransactionSummary = transactionSummary('In', allTxs);
  const formattedOutgoingTransactionSummary = formatTransactionSummary(
    outgoingTransactionSummary
  );
  const formattedIncomingTransactionSummary = formatTransactionSummary(
    incomingTransactionSummary
  );

  let message;

  if (!isSignature) {
    message =
      allTransfers.filter((tx: any) => tx.type === 'In').length > 0 &&
      allTransfers.filter((tx: any) => tx.type === 'Out').length > 0
        ? ` swap ${formattedOutgoingTransactionSummary} for ${formattedIncomingTransactionSummary} `
        : allTransfers.filter((tx: any) => tx.type === 'In').length > 0
        ? ` ${action} ${formattedIncomingTransactionSummary} ${
            preposition ?? 'to'
          } you `
        : ` ${action} ${formattedOutgoingTransactionSummary} ${
            preposition ?? 'to'
          } ${recipient}`;
  } else {
    message = ` allow ${reduceAddress(
      to
    )} to swap ${formattedOutgoingTransactionSummary} for ${formattedIncomingTransactionSummary} `;
  }

  const transactionType = isSignature ? 'signature' : 'transaction';

  const activeChainMessage = ` on the ${NETWORK_INFO[network].network}`;

  return [
    {
      bold: false,
      text: `This ${transactionType} would`,
    },
    {
      bold: true,
      text: message,
    },
    {
      bold: false,
      text: activeChainMessage,
    },
  ];
};

import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import ReportContractModal from '../Footer/ReportContractModal';
import Header from '../Header/Header';

interface ILayoutProps {
  children: React.ReactNode;
  eipGlobalInformation: EIPGlobalInformation;
}

const Layout: React.FC<ILayoutProps> = ({ children, eipGlobalInformation }) => {
  return (
    <div className="no-scrollbar overflow-y-auto bg-[#FAF7FF] h-screen">
      <Header useHeader={!eipGlobalInformation.isFireWalletSimulation} />
      {children}
      {!eipGlobalInformation.isFireWalletSimulation && (
        <ReportContractModal eipGlobalInformation={eipGlobalInformation} />
      )}
    </div>
  );
};

export default Layout;

import axios from 'axios';
import { useState } from 'react';
import { postPhishingReport } from '../../api/simulator';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import { ReportPhishing } from '../../helpers/interfaces/simulator.interface';
import FireButton from '../Shared/FireButton';

interface IReportProps {
  txDetail: EIPGlobalInformation;
  setIsReported: React.Dispatch<React.SetStateAction<boolean>>;
  setUserSelectedReason: React.Dispatch<React.SetStateAction<string>>;
}

const ReportForm = ({
  txDetail,
  setIsReported,
  setUserSelectedReason,
}: IReportProps) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [isInputError, setIsInputError] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');

  const validateAndReport = () => {
    const error =
      selectedReason === '' ||
      (selectedReason === 'Other' && !textValue.trim());
    setIsInputError(error);

    //in the event of whitespace, just delete it to help user
    if (error && textValue && !textValue.trim()) setTextValue('');

    if (!error) {
      const userReportObj: ReportPhishing = {
        simulationId: txDetail.simulationId,
        phishingReason: selectedReason === 'Other' ? textValue : selectedReason,
        sourceUrl: txDetail.sourceUrl ?? '',
      };

      setUserSelectedReason(userReportObj.phishingReason);
      try {
        axios(postPhishingReport(userReportObj));
      } catch (e: any) {
        console.log(e.message);
      }
      setIsReported(true);
      setSelectedReason('');
      setTextValue('');
    }
  };

  const SelectButton = ({ ...props }: any): JSX.Element => {
    return (
      <button
        className={`${
          props.text === selectedReason
            ? 'bg-fire_purple-200 border border-fire_purple-900'
            : ''
        } border w-[266px] h-[44px] mx-auto font-Inter rounded-full text-sm text-left px-5 text-fire_purple-900 transition ease-in-out hover:bg-fire_purple-200 duration-500`}
        onClick={() => {
          setSelectedReason(props.text);
          setIsInputError(false);
        }}
      >
        {props.text}
      </button>
    );
  };

  const TextArea: JSX.Element = (
    <div className="relative mx-auto">
      {selectedReason === 'Other' ? (
        <>
          <span className="absolute left-[20px] top-[13px] z-40">Other</span>
          <textarea
            className="h-[111px] w-[266px] border border-fire_purple-900 rounded-2xl block mx-auto form-control px-5 py-12 focus:outline-none"
            value={textValue}
            placeholder="Type a description..."
            onChange={(e) => setTextValue(e.target.value)}
          ></textarea>
        </>
      ) : (
        <SelectButton text="Other" />
      )}
    </div>
  );

  return (
    <div className="h-auto w-[280px] mx-auto">
      <div className="text-bg-fire_purple-900 text-center text-2xl px-5 font-ClashDisplay font-medium">
        What is malicious about this contract?
      </div>

      <div className="text-red-500 text-center w-full h-2 mb-5">
        {isInputError && 'Oh no, please select a reason!'}
      </div>

      <div className="grid">
        <div className="space-y-3 flex flex-col justify-center">
          <SelectButton text="Trying to drain my wallet" />
          <SelectButton text="Setting approvals I didn't know of" />
          <SelectButton text="Not the correct website" />
          {TextArea}
        </div>
        <FireButton
          className="mt-10 mb-12 mx-auto w-[150px]"
          onClick={() => validateAndReport()}
        >
          Report
        </FireButton>
      </div>
    </div>
  );
};

export default ReportForm;

import { version } from '../../package.json';
import {
  ReportPhishing,
  SignatureData,
  TransactionData,
} from '../helpers/interfaces/simulator.interface';
import { getFullURL } from './config';

export const getSimulatedEipTransaction = (data: TransactionData) => {
  const endPoint = 'simulator/transaction-event';
  return {
    method: 'post',
    url: getFullURL(endPoint),
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const getSimulatedSignature = (data: SignatureData) => {
  const endPoint = 'simulator/signature';
  return {
    method: 'post',
    url: getFullURL(endPoint),
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };
};

export const postPhishingReport = (data: ReportPhishing) => {
  const endPoint = `simulator/${data.simulationId}/phishing-report`;
  return {
    method: 'post',
    url: `${getFullURL(endPoint)}`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      phishingReason: data.phishingReason,
      sourceUrl: data.sourceUrl,
      appVersion: version,
    },
  };
};

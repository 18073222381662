import {
  TRANSACTION_SIMULATION_TYPES,
  NOSIM_SIGNATURE_TYPES,
} from '../../helpers/constants/application.constants';
import { DeepSimulationType } from '../../helpers/enums/application.enums';
import { EIPGlobalInformation } from '../../helpers/interfaces/dataTypes.interface';
import WalletEffectsHeader from '../Shared/WalletEffects';

interface IInteractionInfoViewProps {
  eipGlobalInformation: EIPGlobalInformation;
  transactionType: string;
}

//Renders the necessary component for the Interaction info header
const InteractionInfoView: React.FC<IInteractionInfoViewProps> = ({
  eipGlobalInformation,
  transactionType,
}) => {
  if (
    NOSIM_SIGNATURE_TYPES.includes(transactionType as any) ||
    transactionType === DeepSimulationType.ENS_SIGNATURE_REQUEST
  ) {
    return (
      <WalletEffectsHeader
        loading={eipGlobalInformation.loading}
        data={eipGlobalInformation.rawData}
        isSimulation={false}
      />
    );
  } else if (TRANSACTION_SIMULATION_TYPES.includes(transactionType as any)) {
    return (
      <WalletEffectsHeader
        loading={eipGlobalInformation.loading}
        data={eipGlobalInformation.rawData}
      />
    );
  } else {
    return <></>;
  }
};

export default InteractionInfoView;

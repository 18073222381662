import { useState } from 'react';
import { ERC1155Data } from '../../../helpers/interfaces/dataTypes.interface';
import { useAddressLabels } from '../../../hooks/useAddressLabel';
import Address from '../../Atoms/Address';
import TransactionCard from '../../Containers/TransactionCard';

export interface INftApproveProps {
  balanceChange: ERC1155Data;
  network: string;
}

//TODO: Once the API changes are in, dynamically swap token name with collection name if setApprovalForAll called
const NftApprove: React.FC<INftApproveProps> = ({ balanceChange, network }) => {
  const { data: addressLabelMap, loading } = useAddressLabels(
    [balanceChange.to, balanceChange.from],
    network
  );
  const divider = <div className="w-[145px] h-[1px] bg-gray-200" />;

  const isValidImage = !!balanceChange?.token?.imageUrl;

  //TODO: abstract to its own component. This is used in both NFT cards
  const NftImage: React.FC = () => {
    const [didImageError, setDidImageError] = useState<boolean>(false);

    const ContentImage: JSX.Element = (
      <img
        className="w-[146px] h-[146px] rounded-lg"
        src={isValidImage ? balanceChange?.token?.imageUrl : 'invalid'}
        onError={() => setDidImageError(true)}
      />
    );
    if (!didImageError) {
      return <>{ContentImage}</>;
    }
    return (
      <img
        className="w-[146px] h-[146px] rounded-lg"
        src={'/images/no-nft.png'}
      />
    );
  };

  return (
    <TransactionCard loading={loading}>
      <div className="grid grid-cols-2 h-full">
        <div className="flex flex-col justify-end">
          <NftImage />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <div className="font-ClashDisplay text-secondary-800 text-3xl font-medium">
              {balanceChange.value === 'all' ? 'Collection' : 'Token'}
            </div>
            <span className="font-Manrope">{balanceChange.token.name}</span>
          </div>
          <div>
            <span className="font-Manrope text-gray-400 font-thin text-xs">
              Amount
            </span>
            <div>{balanceChange.value}</div>
          </div>
          {divider}
          <div>
            <span className="font-Manrope text-gray-400 font-thin text-xs">
              To
            </span>
            <Address
              network={network}
              position="top center"
              labeledAddress={addressLabelMap?.get(balanceChange.to)}
              address={balanceChange.to}
            />
          </div>
        </div>
      </div>
    </TransactionCard>
  );
};

export default NftApprove;

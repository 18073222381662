import { ApprovalProps } from '../../components/Simulation/Approvals/Approval';
import { IERC20ApproveProps } from '../../components/Simulation/Approvals/ERC20Approve';
import { NftTransferProps } from '../../components/Simulation/Transfers/NftTransfer';
import {
  ERC1155Data,
  IERC20BalanceChange,
  IRawERC20BalanceChange,
} from '../interfaces/dataTypes.interface';
import { formatCurrency, formatUSD } from '../methods';

export const buildNftTransferProps = (
  rawData: ERC1155Data
): NftTransferProps => {
  return {
    balanceChange: rawData,
    network: '0x1',
  };
};

export const buildApprovalProps = (rawData: any): ApprovalProps => {
  //hardcode type any rn, update this before deploy!
  const props: ApprovalProps = {
    balanceChange: rawData,
  };

  return props;
};

export const buildERC20ApproveProps = (
  rawData: IRawERC20BalanceChange
): IERC20ApproveProps => {
  const balanceChange = buildERC20ApproveNetBalanceChange(rawData);

  const props: IERC20ApproveProps = {
    balanceChange,
  };

  return props;
};

export const buildERC20TransferProps = (
  rawData: IRawERC20BalanceChange
): any => {
  const balanceChange = buildERC20NetBalanceChange(rawData);

  const props: any = {
    balanceChange,
    network: '0x1',
  };

  return props;
};

export const buildERC20ApproveNetBalanceChange = (
  rawData: any
): IERC20BalanceChange => {
  const formattedTokenValue = formatCurrency(
    rawData.value.toString(),
    rawData.token.decimals
  );

  const formattedUSDValue =
    rawData.usdValue !== -1 ? formatUSD(rawData.usdValue) : undefined;

  const formattedBalanceChangeData: IERC20BalanceChange = {
    type: rawData.type,
    to: rawData.to,
    value: formattedTokenValue,
    usdValue: formattedUSDValue,
    logo: rawData.token.logo,
    symbol: rawData.token.symbol,
    verified: rawData.token.verified,
    expiration: rawData.expiration,
  };

  return formattedBalanceChangeData;
};

export const buildERC20NetBalanceChange = (
  rawData: IRawERC20BalanceChange
): IERC20BalanceChange => {
  const formattedTokenValue = formatCurrency(
    rawData.value.toString(),
    rawData.token.decimals
  );

  const formattedUSDValue =
    rawData.usdValue !== -1 ? formatUSD(rawData.usdValue) : undefined;

  const formattedBalanceChangeData: IERC20BalanceChange = {
    type: rawData.type,
    to: rawData.to,
    value: formattedTokenValue,
    usdValue: formattedUSDValue,
    logo: rawData.token.logo,
    symbol: rawData.token.symbol,
    verified: rawData.token.verified,
  };

  return formattedBalanceChangeData;
};

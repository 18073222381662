import {
  DomainDetails,
  VerifiedDomains,
} from '../../helpers/constants/verifiedDomains';
import { signatureIcon } from '../../helpers/icons/icons';
import { getBaseUrl } from '../../helpers/methods';
import DomainCard from './DomainCard';

interface ISignatureBannerProps {
  sourceUrl: string | undefined;
}

const SignatureBanner: React.FC<ISignatureBannerProps> = ({ sourceUrl }) => {
  const dappUrl = getBaseUrl(sourceUrl ?? '');
  const verifiedDappData: DomainDetails | undefined = VerifiedDomains[dappUrl];
  const iconUrl = `/dAppDomainIcons/${verifiedDappData?.icon}`;
  return (
    <div
      className={`h-[148px] bg-secondary-800 p-4 flex flex-col items-center justify-center`}
    >
      <div
        className={`text-2xl text-white flex flex-col justify-center items-center font-ClashDisplay`}
      >
        {verifiedDappData ? (
          <img src={iconUrl} alt="" className="w-[25px] h-[25px] mb-2" />
        ) : (
          <div className="mb-2">{signatureIcon}</div>
        )}
        <div>Signature Request</div>
      </div>
      <DomainCard sourceUrl={sourceUrl} />
    </div>
  );
};

export default SignatureBanner;

import { useContext } from 'react';
import { SimulationContext } from '../../../../contexts/SimulationContext';
import { DeepSimulationType } from '../../../../helpers/enums/application.enums';
import { SplitNetBalanceChanges } from '../../../../helpers/methods';
import TransactionDivider from '../../../Atoms/TransactionDivider';
import TransactionCard from '../../../Containers/TransactionCard';
import SpenderCard from '../../../Shared/SpenderCard';
import TransactionFactory from '../../Transactions/TransactionFactory';
import Empty from '../../Transfers/Empty';
import GasEstimate from '../../Transfers/GasEstimate';
import { PhiLogo } from './PhiLogo';
import { land } from './land';

interface ITransactionsProps {
  netBalanceChanges: any | undefined;
  hexChainId: string;
  userAddress: string;
  transactionType: string;
}

const PhilandTransactions: React.FC<ITransactionsProps> = ({
  netBalanceChanges,
  hexChainId,
  userAddress,
  transactionType,
}) => {
  const { eipGlobalInformation, transactionSimulation } =
    useContext(SimulationContext);

  const { incomingTxs, outgoingTxs, validators } = SplitNetBalanceChanges(
    netBalanceChanges,
    userAddress
  );

  //build the incoming and outgoing transaction components
  const incoming = TransactionFactory(incomingTxs);
  const outgoing = TransactionFactory(outgoingTxs);

  //Label display logic for approvals/revokals
  const isApproval = transactionType.includes('Approve');
  const isRevoke = ['0', 'none'].includes(netBalanceChanges[0].value);
  const isSavingLand =
    eipGlobalInformation.deepSimulationData?.type ===
    DeepSimulationType.PHILAND_LAND_SAVE;
  const isBuying =
    eipGlobalInformation.deepSimulationData?.type ===
    DeepSimulationType.PHILAND_LAND_BUY;
  const isBuyAndDeposit =
    eipGlobalInformation.deepSimulationData?.type ===
    DeepSimulationType.PHILAND_LAND_BUY_AND_DEPOSIT;
  const isCreatingLand =
    eipGlobalInformation.deepSimulationData?.type ===
    DeepSimulationType.PHILAND_LAND_CREATE;
  const outgoingLabel = isApproval ? 'Asset Approvals' : 'Assets Out';
  let incomingLabel = 'Assets In';
  if (isApproval && isRevoke) {
    incomingLabel = 'Revoking From';
  } else if (isApproval) {
    incomingLabel = 'Approval To';
  }

  if (isCreatingLand) {
    return (
      <div className="mb-6 w-[371px] mx-auto p-4">
        <div className="my-3">
          <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
            Creating
          </div>
          <TransactionCard loading={false}>
            <div className="flex items-center h-full w-full">
              <div className="flex flex-col items-center justify-center w-[160px] border rounded-md h-full">
                <span className="font-Manrope font-bold">
                  {eipGlobalInformation.deepSimulationData?.data &&
                    'name' in eipGlobalInformation.deepSimulationData.data && (
                      <div>
                        {`${eipGlobalInformation.deepSimulationData.data.name}.eth`}
                      </div>
                    )}
                </span>
                {land('90', false)}
              </div>
              <div className="font-ClashDisplay font-medium text-2xl ml-3 mb-1">
                New Land
              </div>
            </div>
          </TransactionCard>
        </div>
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    );
  }
  if (isSavingLand) {
    return (
      <div className="-z-10 mb-6 w-[371px] mx-auto p-4">
        <div className="my-3">
          <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
            Saving
          </div>
          <SpenderCard
            customIcon={PhiLogo}
            network={hexChainId}
            logo={undefined}
            spender={
              incomingTxs[0]?.from ??
              eipGlobalInformation?.contractAddress ??
              'unknown'
            }
          />
        </div>
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    );
  }
  if (isBuying) {
    return (
      <div className="-z-10 mb-6 w-[371px] mx-auto p-2">
        <div className="my-3">
          <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
            {incomingLabel}
          </div>
          <SpenderCard
            customIcon={PhiLogo}
            network={hexChainId}
            logo={undefined}
            spender={'Phi Shop'}
            hideLinkout={true}
          />
        </div>
        <TransactionDivider />
        {incoming}
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    );
  }
  if (isBuyAndDeposit) {
    return (
      <div className="-z-10 mb-6 w-[371px] mx-auto p-4">
        <div className="my-3">
          <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
            Assets Out
          </div>
          {outgoing}
        </div>
        <TransactionDivider />
        <div className="mt-2">
          <SpenderCard
            customIcon={land()}
            network={hexChainId}
            logo={undefined}
            spender={'Your Phi Land'}
            hideLinkout={true}
          />
        </div>
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    );
  }

  //Show the divider in cases where there are assets going in and out
  //This excludes 1-sided signatures since there could be multiple counterparties on the other side of the tx
  const showDivider =
    !!incomingTxs.length ||
    (!!outgoingTxs.length &&
      !(
        incomingTxs.length > 1 ||
        (outgoingTxs.length > 1 && transactionSimulation?.signature)
      ));

  return (
    <div className="-z-10 mb-6">
      <div className="h-full w-[371px] mx-auto p-4">
        {/* If no balance changes and just gas show the empty view */}
        {!incomingTxs.length && !outgoingTxs.length && <Empty />}

        {/* If outgoing txs > 1, show the regular view */}
        {!!outgoingTxs.length && (
          <>
            <div className="mb-3">
              <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
                {outgoingLabel}
              </div>
              {outgoing}
            </div>
          </>
        )}
        {/* If no outgoing txns, show counterparty card with details on who txferred the asset to you */}
        {incomingTxs.length > 0 && !outgoingTxs.length && (
          <div className="mb-4">
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              Withdrawing From
            </div>
            <SpenderCard
              customIcon={land()}
              network={hexChainId}
              logo={undefined}
              spender={
                incomingTxs[0]?.from ??
                eipGlobalInformation?.contractAddress ??
                'unknown'
              }
            />
          </div>
        )}
        {/* If we have transactions going in or out, show the divider */}
        {showDivider && <TransactionDivider />}
        {/* If no incoming txns, show counterparty card with details on who the recipient is */}
        {(outgoingTxs.length > 0 && !incomingTxs.length) ||
        ['erc721Approve', 'erc20Approve', 'erc1155Approve'].includes(
          transactionType
        ) ? (
          <div className="my-3">
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              {isApproval ? incomingLabel : 'Depositing To'}
            </div>
            <SpenderCard
              customIcon={land()}
              network={hexChainId}
              logo={undefined}
              spender={
                incomingTxs[0]?.from ??
                eipGlobalInformation?.contractAddress ??
                'unknown'
              }
            />
          </div>
        ) : (
          <></>
        )}
        {/* If incoming txs > 1, show the regular view */}
        {!!incomingTxs.length && (
          <>
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              {incomingLabel}
            </div>
            {incoming}
          </>
        )}
        {/* If we have a gas calc, show gas estimate*/}
        {!!validators.length && (
          <GasEstimate
            className="mt-5 ml-4"
            hexChainId={hexChainId}
            usdPrice={validators[0]?.usdValue ?? -1}
            value={validators[0]?.value}
            decimals={validators[0]?.decimals}
          />
        )}
      </div>
    </div>
  );
};

export default PhilandTransactions;

import TransactionDivider from '../Atoms/TransactionDivider';
import TransactionRowContainer from '../Containers/TransactionRowContainer';
import Header from '../Header/Header';
import SkeletonBanner from './SkeletonBanner';

const GlobalLoader = () => {
  return (
    <div className="bg-[#FAF7FF] h-screen">
      <Header useHeader={true} />
      <SkeletonBanner />
      <div className="p-8">
        <div className="w-[316px] h-[21px] bg-gray-300 rounded-lg animate-tailwindPulse mx-auto mb-2" />
        <div className="w-[131px] h-[21px] bg-gray-300 rounded-lg animate-tailwindPulse" />
        <div className="mt-6 mb-2">
          <TransactionRowContainer loading={false}>
            <div className="flex flex-row justify-between">
              <div>
                <div className="flex items-center mb-2">
                  <div className="h-[32px] w-[32px] bg-gray-100 mr-2 rounded-full animate-tailwindPulse" />
                  <div className="w-[69px] h-[24px] bg-gray-100 rounded-lg animate-tailwindPulse" />
                </div>
                <div className="w-[108px] h-[17px] bg-gray-100 rounded-full animate-tailwindPulse" />
              </div>
              <div>
                <div className="w-[125px] h-[56px] bg-gray-100 rounded-lg animate-tailwindPulse" />
              </div>
            </div>
          </TransactionRowContainer>
        </div>
        <TransactionDivider />
        <div className="w-[131px] h-[21px] mb-2 bg-gray-300 rounded-lg animate-tailwindPulse" />
        <TransactionRowContainer loading={false}>
          <div className="flex flex-row justify-between items-center h-full">
            <div>
              <div className="flex items-center mb-2">
                <div className="h-[32px] w-[32px] bg-gray-100 mr-2 rounded-full animate-tailwindPulse" />
                <div className="w-[106px] h-[24px] bg-gray-100 rounded-lg animate-tailwindPulse" />
              </div>
            </div>
            <div>
              <div className="w-[25px] h-[25px] bg-gray-100 rounded-full animate-tailwindPulse mr-3" />
            </div>
          </div>
        </TransactionRowContainer>
        <div className="w-[131px] h-[21px] my-4 bg-gray-300 rounded-lg animate-tailwindPulse" />
        <div className="flex items-center mb-2">
          <div className="h-[16px] w-[16px] bg-gray-300 mr-1 rounded-full animate-tailwindPulse" />
          <div className="w-[67px] h-[16px] bg-gray-300 rounded-lg animate-tailwindPulse" />
        </div>
      </div>
      <div
        style={{ bottom: 0 }}
        className="w-full fixed bottom-0 h-7 bg-white border border-top align-middle content-center flex justify-center"
      >
        <div className="text-center font-extralight text-sm underline text-slate font-Manrope">
          🚩Report a malicious contract
        </div>
      </div>
    </div>
  );
};

export default GlobalLoader;

export enum EIPTransactionDictionary {
  ERC20_TRANSFER = 'erc20Transfer',
  ERC20_APPROVE = 'erc20Approve',
  ERC721_TRANSFER = 'erc721Transfer',
  ERC721_APPROVE = 'erc721Approve',
  ERC1155_APPROVE = 'erc1155Approve',
}

export enum EIPTransactionTypes {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
  ETH = 'eth',
  ETH_SIG = 'eth_sign',
  PERSONAL_SIG = 'personal_sign',
}

export enum SignatureTypes {
  ETH_SIG = 'eth_sign',
  PERSONAL_SIG = 'personal_sign',
  UNKNOWN = 'unknown_signature',
}

export enum EventType {
  OPEN_EXTENSION = 'OpenExtension',
  SIMULATION_VIEW = 'SimulationView',
  START_SIMULATION = 'StartSimulation',
}

export enum ErrorTypes {
  INSUFFICIENT_FUNDS = 'Insufficient Funds',
  INSUFFICIENT_BALANCE = 'Insufficient Balance',
  NOT_VERIFIED = 'Contract source code not verified',
  INSUFFICIENT_GAS_LIMIT = 'Out of Gas',
  RATE_LIMIT = 'Rate Limit',
  GENERAL_ERROR = 'Simulation Error',
  FRONTEND_ERROR = 'Internal Error',
}

export enum DeepSimulationType {
  ENS_SIGNATURE_REQUEST = 'SignatureRequest',
  ENS_REGISTER = 'ENSRegister',
  POLYGON_NATIVE_BRIDGE_ETH = 'DepositEtherFor',
  POLYGON_NATIVE_BRIDGE_ERC20 = 'DepositFor',
  POLYGON_NATIVE_BRIDGE_WITHDRAW = 'Withdraw',
  PHILAND_LAND_DEPOSIT = 'LandDeposit',
  PHILAND_LAND_WITHDRAW = 'LandWithdraw',
  PHILAND_LAND_CLAIM = 'LandClaim',
  PHILAND_LAND_CREATE = 'LandCreate',
  PHILAND_LAND_SAVE = 'LandSave',
  PHILAND_LAND_BUY = 'LandBuy',
  PHILAND_LAND_BUY_AND_DEPOSIT = 'LandBuyAndDeposit',
}

export const philandDeepSimulationType = [
  DeepSimulationType.PHILAND_LAND_DEPOSIT,
  DeepSimulationType.PHILAND_LAND_WITHDRAW,
  DeepSimulationType.PHILAND_LAND_CLAIM,
  DeepSimulationType.PHILAND_LAND_CREATE,
  DeepSimulationType.PHILAND_LAND_SAVE,
  DeepSimulationType.PHILAND_LAND_BUY,
  DeepSimulationType.PHILAND_LAND_BUY_AND_DEPOSIT,
];

export enum PhilandDeepSimulationAddress {
  PHILAND_NFT_CONTRACT = '0x754e78bc0f7b487d304552810a5254497084970c',
  PHILAND_LAND_CONTRACT = '0xe8b6395d223c9d3d85e162f2cb2023bc9088a908',
  PHILAND_REGISTRY_CONTRACT = '0x6532b97295a0728880ae81d5cd7248f32e24e39a',
  PHILAND_SHOP_CONTRACT = '0xc6e5240054dbe92bde25546cf0c72dc499c41ca8',
}
